<template>
    <div class="smsUser">
        <el-card class="condition">
            <el-row :gutter="20">
                <el-col :span="4" :xs="16" :sm="6" :lg="5" :xl="4">
                    <el-select v-model="filtrate.prodCode" placeholder="请选择设备类型" size="small" clearable @change="alarmTypeSelect" filterable>
                        <el-option
                        v-for="item in devOpt"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="4" :xs="16" :sm="6" :lg="5" :xl="4">
                    <el-select v-model="filtrate.alarmType" placeholder="请选择告警名称" size="small" clearable filterable>
                        <el-option
                        v-for="item in alarmOpt"
                        :key="item.value"
                        :label="item.alarmName"
                        :value="item.alarmType">
                        </el-option>
                    </el-select>
                </el-col>
                <el-col :span="4" :xs="10" :sm="7" :lg="5" :xl="4">
                    <el-button type="success" size="small" round @click="search"><i class="el-icon-search glo-icon-mgr2"></i>搜索</el-button>
                    <el-button type="warning" size="small" round @click="reset"><i class="el-icon-refresh glo-icon-mgr2"></i>重置</el-button>
                </el-col>
            </el-row>
        </el-card>
        <el-card class="table-inner">
            <div class="handle-0">
                <el-button type="primary" size="small" @click="handleAdd">创建告警类型</el-button>
            </div>
            <el-table
                :data="tableData"
                border
                style="width: 100%"
                class="glo-table-switch"
                v-loading="loading"
                element-loading-text="加载中"
            >
                <el-table-column prop="prodCode" label="产品类型"></el-table-column>
                <el-table-column prop="alarmName" label="告警名称" width="160" :show-overflow-tooltip="true"></el-table-column>
                <el-table-column label="告警类型" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <span>{{scope.row.alarmType || '暂无'}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="告警描述" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <span>{{scope.row.content || '暂无'}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="level" label="告警级别" :formatter="dataRule"></el-table-column>
                <el-table-column label="类别">
                    <template slot-scope="scope">
                        <span>{{scope.row.category || '暂无'}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="关联事件">
                    <template slot-scope="scope">
                        <span>{{scope.row.eventCode || '暂无'}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="微信平台转发标志">
                    <template  slot-scope="scope">
                        <el-switch disabled v-model="scope.row.wechatFlag" inactive-color="#ff4949" :active-value="1" :inactive-value="0"></el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="深华达平台转发标志">
                    <template  slot-scope="scope">
                        <el-switch disabled v-model="scope.row.rcsmFlag" inactive-color="#ff4949" :active-value="1" :inactive-value="0"></el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="安全组织平台转发标志">
                    <template  slot-scope="scope">
                        <el-switch disabled v-model="scope.row.safeFlag" inactive-color="#ff4949" :active-value="1" :inactive-value="0"></el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="展示平台转发标志">
                    <template  slot-scope="scope">
                        <el-switch disabled v-model="scope.row.showFlag" inactive-color="#ff4949" :active-value="1" :inactive-value="0"></el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="150">
                    <template slot-scope="scope">
                        <el-button @click="handleEdit(scope.row)" type="primary" size="mini">编辑</el-button>
                        <el-button @click="handleDelete(scope.row.id)" type="danger" size="mini">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pages-0">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pages.current"
                    :page-sizes="pages.option"
                    :page-size="pages.size"
                    layout="total, sizes, prev, pager, next, jumper" :total="pages.total"
                ></el-pagination>
            </div>
        </el-card>

        <el-dialog
            :title="dialogTitle=== 1 ? '创建告警' : '编辑告警' "
            :visible.sync="dialogEdit"
            width="36%"
            @closed="dialogCloseEdit"
        >
            <el-form ref="smsUserForm" :rules="rules" :model="smsUserForm" label-width="100px">
                <el-form-item label="产品类型" v-if="dialogTitle=== 1">
                    <el-select v-model="smsUserForm.prodCode" placeholder="请选择产品类型" clearable style="width:100%;">
                        <el-option v-for="item in this.devOpt" :key="item.value"
                            :label="item.name" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="告警名称" prop="alarmName">
                    <el-input v-model="smsUserForm.alarmName" maxlength="24" size="small" placeholder="告警名称"></el-input>
                </el-form-item>
                <el-form-item label="告警类型" v-if="dialogTitle=== 1">
                    <el-input v-model="smsUserForm.alarmType" maxlength="11" size="small" placeholder="告警类型"></el-input>
                </el-form-item>
                <el-form-item label="告警描述" prop="content">
                    <el-input v-model="smsUserForm.content" maxlength="24" size="small" placeholder="告警描述"></el-input>
                </el-form-item>
                <el-form-item label="告警级别">
                    <el-select v-model="smsUserForm.level" placeholder="请选择告警级别" clearable style="width:100%;">
                        <el-option v-for="item in this.levelOpt" :key="item.value"
                            :label="item.name" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="类别">
                    <el-input v-model="smsUserForm.category" size="small" placeholder="类别"></el-input>
                </el-form-item>
                <el-form-item label="关联事件">
                    <el-input v-model="smsUserForm.eventCode" size="small" placeholder="关联事件"></el-input>
                </el-form-item>
                <div class="flagClass">
                    <el-form-item label="微信平台转发标志" class="formClass">
                        <el-switch v-model="smsUserForm.wechatFlag" inactive-color="#ff4949" :active-value="1" :inactive-value="0"></el-switch>
                    </el-form-item>
                    <el-form-item label="深华达平台转发标志" class="formClass">
                        <el-switch v-model="smsUserForm.rcsmFlag" inactive-color="#ff4949" :active-value="1" :inactive-value="0"></el-switch>
                    </el-form-item>
                    <el-form-item label="安全组织平台转发标志" class="formClass">
                        <el-switch v-model="smsUserForm.safeFlag" inactive-color="#ff4949" :active-value="1" :inactive-value="0"></el-switch>
                    </el-form-item>
                    <el-form-item label="展示平台转发标志" class="formClass">
                        <el-switch v-model="smsUserForm.showFlag" inactive-color="#ff4949" :active-value="1" :inactive-value="0"></el-switch>
                    </el-form-item>
                </div>
            </el-form>
            <span slot="footer">
                <el-button type="primary" @click="submit" size="small">提 交</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { selectDictLabel } from "@/util/selectDictLabel";
export default {
    data(){
        return{
            loading:true,
            pages:{
                current:1,
                option:[8, 10, 12,20],
                size:10,
                total:0
            },
            devOpt:[
                {name:'720C',value:'itgs'},
                {name:'安全哨卫',value:'bucket'},
                {name:'气象精灵',value:'melves'},
                {name:'充电包',value:'icps'},
                {name:'水位精灵',value:'wlms'},
                {name:'智能安全帽',value:'helmet'},
                {name:'扩展模块',value:'extend'},
                {name:'接触网',value:'jcw'},
                {name:'平安花',value:'pah'},
                {name:'护栏',value:'wgs'},
                {name:'隧检机器人',value:'robot'},
                {name:'团雾监测',value:'fog'},
                {name:'桥检边坡',value:'bhms'},
                {name:'环境检测仓',value:'env'},
                {name:'危险源',value:'hazard'},
            ],//设备类型
            levelOpt: [
                {name:'一级',value:'1'},
                {name:'二级',value:'2'},
                {name:'三级',value:'3'},
                {name:'四级',value:'4'},
                {name:'五级',value:'5'},
                {name:'六级',value:'6'},
                {name:'七级',value:'7'},
            ],//告警级别
            alarmOpt:[],//告警类型
            tableData: [],
            currentPage:1,
            filtrate:{
                prodCode:'itgs'
            },
            smsUserForm:{},
            rules:{
                name: [
                    { required: true, message: '请填写姓名', trigger: 'blur' },
                    { min: 2, max: 12, message: '长度在2 到 12 个字符', trigger: 'blur' }
                ],
                phone: [
                    { required: true, message: '请填写手机号', trigger: 'blur' },
                    { min: 2, max: 12, message: '长度11 个字符', trigger: 'blur' }
                ],
            },
            dialogTitle:1,
            dialogEdit:false,
            dialogData:false,
        }
    },
    methods:{
        handleSizeChange(size) {
            this.pages.current = 1;
            this.pages.size = size;
            this.loading = true;
            this.loadData();
        },
        handleCurrentChange(page) {
            this.loading = true;
            setTimeout( ()=>{
                this.pages.current = page;
                this.loadData();
            },100)
        },
        // 筛选-检索
        search(){
            this.loading = true;
            this.pages.current=1;
            setTimeout(()=>{
                this.loadData();
            },500)
        },
        // 筛选-重置
        reset(){
            this.filtrate={
                prodCode:'itgs'
            }
            this.getAlarmTypeData('itgs');
            this.search();
        },
        // 增加用户
        handleAdd(){
            this.dialogTitle = 1;
            this.dialogEdit = true;
        },
        // 编辑用户信息
        handleEdit(row){
            this.dialogTitle = 2;
            this.dialogEdit = true;
            this.smsUserForm = {...row}
        },
        // 关闭编辑对话框
        dialogCloseEdit(){
            this.smsUserForm = {
                smsUserName:'',
                sortIndex:'',
                smsUserDesc:'',
            }
            setTimeout(()=>{
                this.$refs['smsUserForm'].clearValidate();
            },10)
        },
        // 提交保存
        submit(){
            let data = {...this.smsUserForm};
            this.$refs['smsUserForm'].validate((valid) => {
                if (valid) {
                    if(this.dialogTitle === 1){
                        // 新增
                        delete data.id;
                        this.$api.alarmTypeSave(data).then( d=> {
                            this.$message({
                                type: 'success',
                                message: '创建成功!'
                            });
                            this.dialogEdit = false;
                            this.loading = true;
                            this.pages.current = 1;
                            setTimeout(()=>{
                                this.getAlarmTypeData(data.prodCode);
                                this.filtrate.prodCode = data.prodCode;
                                this.loadData();
                            },500)
                        })
                    }else if(this.dialogTitle === 2){
                        // 修改
                        this.$api.alarmTypeUpdate(data).then( d=> {
                            this.$message({
                                type: 'success',
                                message: '更新成功!'
                            });
                            this.dialogEdit = false;
                            this.loading = true;
                            this.pages.current = 1;
                            setTimeout(()=>{
                                this.loadData();
                            },500)
                        })
                    }

                } else {
                    return false;
                }
            });
        },
        // 删除告警
        handleDelete(id){
            this.$confirm('确定要删除该联系人吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$api.alarmTypeDel(id).then( d =>{
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                        duration:1500
                    });
                    this.loading = true;
                    this.pages.current = 1;
                    setTimeout(()=>{
                        this.loadData();
                    },500)
                })
            }).catch(() => {})
        },
        loadData(){
            return new Promise( (resolve,rejuect) => {
                this.$api.alarmTypePage(this.pages.current,this.pages.size,this.filtrate).then( d => {
                    if(d?.records?.length){
                        this.tableData = d.records;
                        this.pages.total = +d.total;
                    }else{
                        this.tableData = [];
                    }
                    this.loading = false;
                    resolve();
                })
            })
        },
        //监听设备类型切换
        alarmTypeSelect (value) {
            this.getAlarmTypeData(value);
        },
        //获取全部告警信息
        getAlarmTypeData (value) {
            this.$api.alarmTypeList({prodCode:value}).then( d => {
                if(d?.length){
                    this.alarmOpt = d;
                }
            })
        },
        dataRule: function(row, column) {
                switch (column.property) {
                    case "level":
                        return selectDictLabel(
                            this.levelOpt,
                            row.level,
                            "value",
                            "name"
                        );
                }
            },
    },
    mounted(){
        this.loadData();
        this.getAlarmTypeData('itgs');
    }
}
</script>

<style lang="scss" scoped>
.smsUser{
    width: calc(100% - 10px);
    height: 100%;
    overflow-y: auto;
    .condition{
        margin-bottom: 10px;
        .el-col{
            display: inline-block;
            margin: 5px 0;
        }
    }
    .table-inner{
        .handle-0{
            margin-bottom: 10px;
        }
        .pages-0{
            margin-top: 10px;
            text-align: right;
        }
    }
    .menu-dialog{
        border: 1px solid #ccc;
        padding: 4px;
        height: 20rem;
    }
    .mgb10{
        margin-bottom: 10px;
    }
    .flagClass {
        display: flex;
        .formClass {
            width: 30%;
            margin-left: 10px;
        }
    }
}
</style>
